/* Import Ant Design styles by less entry */
/* variables to override above */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #2d3039;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#portal-auth-ui {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  min-height: 100vh;
}
#portal-auth-ui input {
  -webkit-appearance: none;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
#portal-auth-ui input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
#portal-auth-ui .text-field,
#portal-auth-ui .input {
  border: none;
  border-radius: 0;
  outline: none;
}
#portal-auth-ui .text-field input,
#portal-auth-ui .input input {
  border-top: none;
  outline: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #9da6c0;
}
#portal-auth-ui .text-field input:hover,
#portal-auth-ui .input input:hover,
#portal-auth-ui .text-field input:active,
#portal-auth-ui .input input:active,
#portal-auth-ui .text-field input:focus,
#portal-auth-ui .input input:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #0084ff;
}
#portal-auth-ui .with-tip {
  position: relative;
}
#portal-auth-ui .with-tip::before {
  content: attr(data-tip);
  position: absolute;
  bottom: -60%;
  font-size: 10px;
  font-weight: 500;
  color: #9da6c0;
}
#portal-auth-ui .tip-success-color::before {
  color: #36c535;
}
#portal-auth-ui .tip-warning-color::before {
  color: #f85335;
}
#portal-auth-ui .tip-error-color::before {
  color: #f85335;
}
.page {
  padding: 30px;
}
@media (max-width: 768px) {
  .page {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .page {
    padding: 5px;
  }
}
.icon > svg {
  fill: #b1b8cd;
  transition: 0.2s;
}
.icon-hover {
  padding: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  transition: 0.4s;
}
.icon-hover:hover,
.icon-hover:focus {
  background-color: #f4f7fc;
  transition: 0.4s;
}
.icon-hover.fill-primary:hover > svg,
.icon-hover.fill-primary:focus > svg {
  fill: #acc437;
  transition: 0.2s;
}
.module-icon {
  color: #ffffff;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
}
.bi-module-icon {
  background-color: #f85334 !important;
}
.pg-module-icon {
  background-color: #acc437 !important;
}
.tt-module-icon {
  background-color: #0084ff !important;
  font-family: 'Montserrat', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hover-pointer {
  transition: 0.4s;
}
.hover-pointer:hover,
.hover-pointer:focus {
  cursor: pointer;
  opacity: 0.75;
  transition: 0.4s;
}
