@import (reference) '../../theme.less';

#page-spinner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 100000;
  position: fixed;
}

.spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .spinner-title {
    color: @text-accent-color;
    font-size: 16px;
    font-weight: 600;
    padding-top: 15px;
  }
}
